<template>
  <div>
    <vside
      @hide="fechar()"
      :active="modalFaturamentoAtrasado"
      :item="{}"
      size="huge"
      textSucces
    >
      <div slot="body">
        <div
          id="faturamento-atrasado"
          class="cardTable mb-1 vs-con-loading__container"
        >
          <div
            class="mt-1"
            v-if="
              faturamentoAtrasado.length == 0 ||
              faturamentoAtrasado.length == undefined
            "
          >
            <div class="scroll-faturamento-atrasado pt-20 con-colors">
              <ul class="pt-20">
                <li class="danger-box">
                  <h2 class="p-5" style="color: white !important">
                    Nenhum Faturamento Atrasado
                  </h2>
                </li>
              </ul>
            </div>
          </div>
          <div v-else>
            <div>
              <div>
                <vx-card card-background="dark">
                  <div class="p-2" style="color: white !important">
                    <span style="font-size: 20px">
                      <b style="text-transform: uppercase"
                        >FATURAMENTOS ATRASADOS:</b
                      >
                      - {{ faturamentoAtrasado.length }}
                    </span>
                  </div>
                </vx-card>
              </div>
            </div>
            <!-- <VuePerfectScrollbar
              class="scroll-faturamento-atrasado"
              :settings="settings"
            >-->
            <vs-table
              class="px-2"
              :data="faturamentoAtrasado"
              stripe
              maxHeight="80vh"
              pagination
              max-items="15"
              id="tableFat"
            >
              <template slot="thead">
                <vs-th sort-key="id_contrato" style="width: 5%" id="center"
                  >Contrato</vs-th
                >
                <vs-th sort-key="id_cliente" style="width: 10%" id="center"
                  >Cliente</vs-th
                >
                <vs-th sort-key="id_empresa" style="width: 10%" id="center"
                  >Empresa</vs-th
                >
                <vs-th style="width: 10%" id="center">Tipo Contrato</vs-th>
                <vs-th style="width: 10%" id="center">Parcela</vs-th>
                <vs-th style="width: 10%" id="center">Data Competência</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(row, index) in data">
                  <vs-td :data="row.id_contrato" class="text-center">
                    <a
                      target="_blank"
                      :href="'contrato?id=' + row.id_contrato"
                      style="font-size: 12px; color: darkblue; cursor: pointer"
                    >
                      <b>{{ row.id_contrato }}</b>
                    </a>
                  </vs-td>
                  <vs-td
                    :data="row.id_cliente"
                    class="text-center small-font"
                    >{{ row.nome_cliente }}</vs-td
                  >
                  <vs-td
                    :data="row.id_empresa"
                    class="text-center small-font"
                    >{{ row.empresa }}</vs-td
                  >
                  <vs-td class="text-center small-font">{{
                    row.tipo_contrato
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.parcela
                  }}</vs-td>
                  <vs-td class="text-center small-font">{{
                    row.data_competencia
                  }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- </VuePerfectScrollbar> -->
          </div>
        </div>
      </div>
    </vside>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    filtro: null
  },
  data () {
    return {
      faturamentoAtrasado: {},
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 1,
        height: 1000
      },
      modalFaturamentoAtrasado: false
    }
  },
  methods: {
    async fechar () {
      this.modalFaturamentoAtrasado = false
    },
    async getData () {
      await this.$vs.loading({
        container: "#faturamento-atrasado",
        scale: 0.6
      })
      try {
        this.faturamentoAtrasado = await this.$http.post(
          "getFaturamentosAtrasados",
          this.filtro
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#faturamento-atrasado > .con-vs-loading")
      }
    }
  },
  async mounted () {
    this.modalFaturamentoAtrasado = true
    await this.getData()
  },
  components: {
    ...components
  }
};
</script>
<style lang="scss">
.scroll-faturamento-atrasado {
  height: 90vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
