<template>
  <div>
    <vs-row
      vs-type="flex"
      class="mt-0 mb-4"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="12"
      >
        <vs-input
          color="dark"
          class="mx-1"
          type="number"
          size="small"
          label="Contrato N°"
          v-model="filtro.id_contrato"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          type="number"
          size="small"
          label="Parcela N°"
          v-model="filtro.parcela"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          size="small"
          label="Data De"
          v-model="filtro.dataDe"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          size="small"
          label="Data Até"
          v-model="filtro.dataAte"
        ></vs-input>
        <div>
          <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
            >Vendedor</label
          >
          <br />
          <el-select
            filterable
            size="small"
            clearable
            v-model="filtro.vendedor"
            class="mx-1 mt-2"
          >
            <el-option
              v-for="vendedor in vendedores"
              :value="vendedor.id"
              :label="vendedor.nome"
              :key="vendedor.id"
            ></el-option>
          </el-select>
        </div>
        <div>
          <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
            >Empresa</label
          >
          <br />
          <el-select
            size="small"
            filterable
            clearable
            v-model="filtro.empresa"
            class="mx-1 mt-2"
          >
            <el-option
              v-for="empresa in empresas"
              :value="empresa.id"
              :label="empresa.razao_social"
              :key="empresa.id"
            ></el-option>
          </el-select>
        </div>
        <div>
          <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
            >Tipo Contrato</label
          >
          <br />
          <el-select
            size="small"
            filterable
            clearable
            v-model="filtro.tipo_faturamento"
            class="mx-1 mt-2"
          >
            <el-option
              v-for="tipo in tipos_faturamento"
              :value="tipo.id"
              :label="tipo.nome"
              :key="tipo.id"
            ></el-option>
          </el-select>
        </div>
        <div>
          <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
            >Status</label
          >
          <br />
          <el-select
            filterable
            size="small"
            clearable
            v-model="filtro.status"
            class="mx-1 mt-2"
          >
            <el-option
              v-for="data in status"
              :value="data.id"
              :label="data.nome"
              :key="data.id"
            ></el-option>
          </el-select>
        </div>
        <div>
          <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
            >Tipo Pessoa</label
          >
          <br />
          <el-select
            filterable
            clearable
            size="small"
            :popper-append-to-body="false"
            v-model="filtro.tipo_pessoa"
            class="mx-1 mt-2"
          >
            <el-option
              v-for="tipo in tipo_pessoa"
              :value="tipo.value"
              :label="tipo.nome"
              :key="tipo.value"
            ></el-option>
          </el-select>
        </div>
        <el-button
          size="small"
          type="primary"
          :disabled="blockButtons"
          class="mx-1"
          plain
          @click="getContratosFaturar()"
          >FILTRAR</el-button
        >
      </vs-col>
    </vs-row>
    <div
      id="contratos-faturamento-loading"
      class="cardTable mb-1 vs-con-loading__container"
    >
      <div v-if="contratos.length == 0 || contratos.length == undefined">
        <div class="pt-20 scroll-contratos-faturamento con-colors">
          <ul class="pt-20">
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">
                Nenhum Contrato para Faturar
              </h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="mt-1 scroll-contratos-faturamento">
        <!-- <VuePerfectScrollbar
          class="scroll-contratos-faturamento"
          :settings="settings"
        >-->
        <vs-table
          max-items="10"
          pagination
          stripe
          maxHeight="65vh"
          :data="contratos"
          id="tableContasPagar"
        >
          <template slot="thead">
            <vs-th sort-key="id_contrato" style="width: 10%" id="center"
              >N° Contrato</vs-th
            >
            <vs-th style="width: 20%">Cliente</vs-th>
            <vs-th style="width: 20%">Empresa</vs-th>
            <vs-th style="width: 12%" id="center">Tipo Contrato</vs-th>
            <vs-th style="width: 12%" id="center">Data Competência</vs-th>
            <vs-th style="width: 12%" id="center">Data Faturamento</vs-th>
            <vs-th style="width: 12%" id="center">Valor</vs-th>
            <vs-th style="width: 12%" id="center">Parcela</vs-th>
            <vs-th style="width: 12%" id="center">Status</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr
              :data="contrato"
              :key="index"
              v-for="(contrato, index) in data"
              :style="
                contrato.id_status == 2
                  ? 'color: #dd3522;background-color:#f5efef !important;'
                  : contrato.id_status == 3
                  ? 'color: green; background-color: #e8fce8 !important'
                  : contrato.id_status == 4
                  ? 'color:#00BBA2;background-color: #dff2f0 !important'
                  : ''
              "
            >
              <vs-td :data="contrato.id_contrato" class="text-center">
                <a
                  target="_blank"
                  :href="'contrato?id=' + contrato.id_contrato"
                  style="font-size: 12px; color: darkblue; cursor: pointer"
                >
                  <b>{{ contrato.id_contrato }}</b>
                </a>
              </vs-td>
              <vs-td style="font-size: 12px">
                <VisualizarCliente
                  @update="close()"
                  :id_cliente="contrato.id_cliente"
                  :nomeCliente="contrato.cliente"
                />
              </vs-td>
              <vs-td style="font-size: 12px">
                <b>{{ contrato.empresa }}</b>
              </vs-td>
              <vs-td class="text-center" style="font-size: 12px">
                <b style="text-transform: uppercase !important">
                  {{ contrato.tipo_faturamento }}
                </b>
              </vs-td>
              <vs-td class="text-center">
                {{
                  contrato.data_competencia ? contrato.data_competencia : "--"
                }}
              </vs-td>
              <vs-td class="text-center">
                {{
                  contrato.data_faturamento ? contrato.data_faturamento : "--"
                }}
              </vs-td>
              <vs-td class="text-center">{{
                contrato.valor ? $currency(contrato.valor) : "--"
              }}</vs-td>
              <vs-td class="text-center">{{ contrato.parcela }}</vs-td>
              <vs-td style="font-size: 12px" class="text-center">
                <b style="text-transform: uppercase !important">{{
                  contrato.status
                }}</b>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!-- </VuePerfectScrollbar> -->
      </div>
    </div>
    <vx-card no-shadow card-background="dark">
      <vs-row
        vs-type="flex"
        class
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1.5"
        >
          <vs-button
            @click="voltar()"
            icon-pack="feather"
            line-origin="left"
            color="white"
            type="line"
            icon="icon-arrow-left"
            size="medium"
          >
            <span style="font-size: 14px">Voltar</span>
          </vs-button>
        </vs-col>
        <vs-col
          vs-w="2.5"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <vs-button
            :disabled="blockButtons"
            line-origin="left"
            color="white"
            type="line"
            size="medium"
          >
            <span style="font-size: 11px; font-weight: 600">
              {{
                "Para Realizar: " +
                totalizadores.total +
                " (" +
                $currency(totalizadores.valor_total) +
                ")"
              }}
            </span>
          </vs-button>
        </vs-col>
        <vs-col
          vs-w="2.5"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <vs-button
            line-origin="left"
            color="success"
            type="line"
            :disabled="blockButtons"
            size="medium"
          >
            <span style="font-size: 11px; font-weight: 600">
              {{
                "Foram Realizados: " +
                totalizadores.concluidos +
                " (" +
                $currency(totalizadores.valor_concluidos) +
                ")"
              }}
            </span>
          </vs-button>
        </vs-col>
        <vs-col
          vs-w="2.5"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <vs-button
            line-origin="left"
            color="danger"
            type="line"
            :disabled="blockButtons"
            size="medium"
          >
            <span style="font-size: 11px; font-weight: 600">
              {{
                "Ainda Restantam: " +
                totalizadores.restantes +
                " (" +
                $currency(totalizadores.valor_restantes) +
                ")"
              }}
            </span>
          </vs-button>
        </vs-col>
        <vs-col vs-w="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
          <vs-button
            line-origin="left"
            color="primary"
            :disabled="blockButtons"
            type="line"
            @click="verFaturamentoAtrasado()"
            size="small"
          >
            <span style="font-size: 11px; font-weight: 600">
              {{
                " Atrasados: " +
                totalizadores.atrasados +
                " (" +
                $currency(totalizadores.valor_atrasados) +
                ")"
              }}
            </span>
          </vs-button>
        </vs-col>
      </vs-row>
    </vx-card>
    <component
      v-bind:is="modalFaturamentoAtrasado"
      :key="reRenderModalFaturamentoAtrasado"
      :filtro="filtro"
    />
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import VisualizarCliente from "./../../../cliente/visualizar.vue"
import faturamentoAtrasadoSideBar from "./components/faturamentoAtrasadoSideBar"
export default {
  data () {
    return {
      tipo_pessoa: [
        { value: 1, nome: "PESSOA FÍSICA" },
        { value: 2, nome: "PESSOA JURÍDICA" }
      ],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.5,
        height: 1000
      },
      contratos: [],
      totalizadores: {
        total: 0,
        valor_total: 0,
        concluidos: 0,
        valor_concluidos: 0,
        restantes: 0,
        valor_restantes: 0,
        atrasados: 0,
        valor_atrasados: 0
      },
      filtro: {
        data_competencia: this.$formartData.dataFiltro(),
        dataHoje: this.$formartData.dataFiltro()
      },
      empresas: {},
      tipos_faturamento: {},
      vendedores: {},
      status: {},
      blockButtons: false,
      //faturamento atrasado
      modalFaturamentoAtrasado: "",
      reRenderModalFaturamentoAtrasado: 0
    }
  },
  methods: {
    async getContratosFaturar () {
      await this.$vs.loading({
        container: "#contratos-faturamento-loading",
        scale: 0.6
      })
      this.blockButtons = true
      try {
        const res = await this.$http.post(`getContratosFaturar`, this.filtro)
        this.contratos = res.contratos
        this.totalizadores = res.totalizadores
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.blockButtons = false
        await this.$vs.loading.close(
          "#contratos-faturamento-loading > .con-vs-loading"
        )
      }
    },
    async getEmpresas () {
      try {
        this.empresas = await this.$http.get(`empresa`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getFaturamentos () {
      try {
        this.tipos_faturamento = await this.$http.get(`tipo_faturamento`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getVendedores () {
      try {
        this.vendedores = await this.$http.get(`colaborador`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getFaturamentoStatus () {
      try {
        this.status = await this.$http.get(`faturamento_status`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async voltar () {
      this.$router.push("/financeiro")
    },
    async verFaturamentoAtrasado () {
      this.modalFaturamentoAtrasado = "faturamentoAtrasadoSideBar"
      this.reRenderModalFaturamentoAtrasado += 1
    }
  },
  async mounted () {
    await this.getContratosFaturar()
    await this.getEmpresas()
    await this.getFaturamentos()
    await this.getFaturamentoStatus()
    await this.getVendedores()
  },
  components: {
    ...components,
    VisualizarCliente,
    faturamentoAtrasadoSideBar
  }
};
</script>
<style lang="scss">
.scroll-contratos-faturamento {
  height: 73vh !important;
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
